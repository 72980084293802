<template>
  <div>
    <div class="function-descption">
      密码强度规则，最少6位，包括至少1个大写字母，1个小写字母，1个数字
    </div>
    <el-form
      style="margin-top: 30px; width: 600px"
      :model="ruleForm"
      status-icon
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label="原密码" prop="oldpass">
        <el-input v-model="ruleForm.oldpass" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="pass">
        <el-input v-model="ruleForm.pass" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="checkPass">
        <el-input v-model="ruleForm.checkPass" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >提交</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  data() {
    var checkPass = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('不能为空'));
      }
      if (/^.*(?=.{6,})(?=.*\d)(?=.*[A-Z])(?=.*[a-z]).*$/.test(value)) {
        callback();
      } else {
        callback(
          new Error(
            '密码强度规则，最少6位，包括至少1个大写字母，1个小写字母，1个数字'
          )
        );
      }
    };
    return {
      ruleForm: {
        pass: '',
        checkPass: '',
        age: ''
      },
      rules: {
        pass: [{ required: true, validator: checkPass, trigger: 'change' }],
        checkPass: [
          { required: true, validator: checkPass, trigger: 'change' }
        ],
        oldpass: [
          { required: true, message: '请输入原密码', trigger: 'change' }
        ]
      }
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$message('密码修改成功');
          this.$router.push({
            path: '/system/index/dashboard'
          });
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
};
</script>
